body{
  margin: 0;
  padding: 0;
  font-family: "Poppins";
}

.common-font{
  font-family: "Poppins !important";
}
.common_font{
  font-family: "Poppins";
}

.common-display-prop{
  display: flex;
  align-items: center;
  flex-direction: row;
}

/* conatct header  */

.contact-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 40px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ccc;
}

.email-section{
  display: flex;
  align-items: center;
  width: 50%;
}

.contact-info-second{
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: space-between;
}

.call-section,
.language-section,
.text-size-section,
.contact-info-second {
  display: flex;
  align-items: center;
}

.icon-email::before{
  content: '';
  display: inline-block;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  
}
.icon-phone::before {
  content: '';
  display: inline-block;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  margin-right: 5px;
}
.icon-email-span{
  color: #274699;
  font-weight: 500;
  font-size: 14px;
}
.icon-email-a{
  
  font-size: 14px;
}

.contact-header-txt{
  font-size: 14px;
}

.contact-header-spl-txt{
  color: #274699 !important;
  font-weight: 500;
}


.icon-email::before {
  /* background-image: url('/path/to/email-icon.png'); */
  margin-right:10px ;
}

.icon-phone::before {
  /* background-image: url('/path/to/phone-icon.png');  */
}

.email-section span,
.call-section span {
  margin-right: 5px;
}

.language-link {
  margin-right: 5px;
  text-decoration: none;
  color: #000;
}

.language-link:hover {
  text-decoration: underline;
}

.text-size-label,
.text-size-control {
  margin-right: 5px;
  cursor: pointer;
}

.text-size-control {
  font-weight: bold;
  font-size: 1.2em;
}


/* main header  */

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
}

.logo-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 30px;
}

.logo {
  width: 150px; 
  height: auto;
  /* margin-right: 10px; */
  margin-bottom: 2px;
}

.logo-text {
  font-size: 10px;
  color: #274699 !important;
  font-weight: bold;
  /* line-height: 6px;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px; */
}

.navigation-section {
  display: flex;
  align-items: center;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.main-header-nav-link {
  color: #000;
  text-decoration: none;
  font-size: 0.9em;
  position: relative;
}

.main-header-spl-txt{
  color: #274699 !important;
  font-weight: 600;
}

.main-header-txt{
  font-weight: 600;
}


.nav-link {
  color: #000;
  text-decoration: none;
  font-size: 16px;
  position: relative;
  display: flex;
  align-items: center;
}




.contact-button {
  background-color: #e60000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  margin-left: 20px;
  font-family: "Poppins";
}

.contact-button:hover {
  background-color: #cc0000;
}


/* navigation header  */

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #23498c;
  padding: 10px 20px;
  height: 75px;
}

.navbar {
  display: flex;
  align-items: center;
  gap: 15px;
  
}

.navbar-main-div{
display: flex;
flex-direction: row;
justify-content: space-between !important;
align-items: center;
}

.nav-link{
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px 3px;
  border-radius: 4px;
  position: relative;
}
.dropbtn {
  color: #000;
  text-decoration: none;
  font-size: 16px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 4px;
  position: relative;
}

.nav-link:hover,
.dropbtn:hover {
  background-color: #1a376b;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.hamburger-menu {
  /* display: none; */
}

.hamburger-icon {
  width: 30px;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.hamburger-icon div {
  width: 100%;
  height: 4px;
  background-color: white;
  border-radius: 2px;
}

/* home page  */

.main-img{
  width: 100%;
  height: auto;
}


.announcements-container {
  /* display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 20px; */
  background-color: #EEEEEE;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  margin-top: -10px;
  padding-left: 30px;
}

.announcement {
  margin: 40px 10px;
  /* width: 200px; */
  position: relative;
  padding: 10px;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  /* background-color: #fff; */
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}

.new-label {
  position: absolute;
  top:-15px;
  left: 10px;
  background-color: #FFE587;
  color: #E31D23;
  padding: 1px 5px;
  border-radius: 5px;
  font-size: 12px;
}

.announcement-text {
  display: block;
  font-size: 14px;
  color: #0044cc;
  margin-bottom: 5px;
  text-decoration: none;
  font-weight: 600;
}

.announcement-text:hover {
  text-decoration: underline;
}

.announcement-date {
  font-size: 12px;
  color: #777;
}



.payment-section {
  display: flex;
  /* justify-content: space-between; */
  margin: 50px 0;
  width: 100%;
}

.quick-bill{
  background-color: #274699;
  padding: 20px;
  border-radius: 8px;
  width: 45%; 
  margin-right: 10px;
  margin-left: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.e-seva {
  background-color: #274699;
  padding: 20px;
  border-radius: 8px;
  width: 45%;
  margin-right: 50px;
  margin-left: 10px;
}

.quick-bill h2,
.e-seva h2 {
  color: #00274d;
}

.pay-bill-box{
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  width: 300px;
  height: 345px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.e-seva-box {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  /* margin-top: 20px; */
  width: 300px;
  height: 345px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 20px 10px 20px 10px;
}

.pay-bill-box-input{
  background-color: #F3F3F3;
  border-radius: 5px;
  border: 0;
  padding: 10px;
  width: 250px;
}

.pay-now-btn{
  background-color: #e63946;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}
.signup-btn,
.login-btn {
  background-color: #e63946;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
/* FFF4CC */ 
/* C7FFC6 */
/* F6D3FF */
.services-section {
  display: flex;
  justify-content: space-between;
  margin: 20px 30px;
}

.service-box {
  padding: 20px 20px 0px 20px;
  border-radius: 8px;
  width: 30%;
  text-align: center;
  margin: 0px 20px;
  display: flex;
  flex-direction: row;
  height: 184px !important;
}

.service-box-clr-1{
  background-color: #FFF4CC;
}
.service-box-clr-2{
  background-color: #C7FFC6;
}
.service-box-clr-3{
  background-color: #F6D3FF;
}

.service-box-sub{
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 20px;
  /* justify-content: left; */
}

.service-box h3 {
  color: #00274d;
}

.service-box a {
  display: inline-block;
  margin-top: 15px;
  color: #0066cc;
  text-decoration: none;
}

.solar-section {
  /* background-color: #00274d; */
  display: flex;
  flex-direction: row;
  height: 551px;
  margin-top: 100px;
}

.solar-sub-section {
  background-color: #274699;
  color: white;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
  width: 50%;
  padding: 20px;
}
.solar-image {
  /* margin-top: 20px; */
  width: 50%;
}

.solar-section h2 {
  margin-bottom: 20px;
}

.solar-section p {
  margin-bottom: 30px;
}

.apply-now-btn {
  background-color: #E31D23;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
}


.download-section {
  text-align: center;
  padding: 40px 20px;
  background-color: #f2f4f7;
 
}

.download-sec-image-2{
  position: relative;
margin-top: -520px;
margin-right: 360px;
height: 500px;
}

.download-sec-image-3{
  position: relative;
margin-top: -438px;
margin-right: -380px;
}

.download-section h2 {
  color: #00274d;
}

.download-section p {
  margin: 20px 0;
}

.app-preview {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.download-btn {
  background-color: #e63946;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 50px;
}
.app-store-links-1{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.app-store-links img {
  /* width: 150px; */
  margin: 0px;
  padding: 0px ;

}

.media-gallery-section {
  text-align: center;
  padding: 40px 20px;
  background-color: #274699;
  color: white;
}

.media-gallery-section h2 {
  margin-bottom: 20px;
}

.media-gallery {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 50px;
}

.media-gallery-img-1 {
  border-radius: 50%;
  width: 428px;
  height: 425px;
  margin:0px 30px ;
}

.media-gallery-img-2 {
  width: 424px;
  height: 418px;
  margin:0px 30px ;
}

.action-section {
  display: flex;
  justify-content: space-between;
  padding: 40px 20px;
  /* background-color: #f2f4f7; */
  background-image: url('./assets/img/Frame\ 154.png');
  /* width: 100%; */
  /* height: 576px; */
  
}

.action-box {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  width: 23%;
  text-align: center;
  margin: 20px 20px 100px 20px;
  height: 280px;
  border: 1px solid #274699;
}

.action-box h3 {
  color: #00274d;
}

.action-btn {
  background-color: #e63946;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}


.footer {
  background-color: #274699;
  color: white;
  padding: 40px 20px;
  font-family: Arial, sans-serif;
}

.footer-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 80px;
}

.footer-logo-section{
  /* flex: 1; */
  margin: 10px;
  width: 400px !important;
}
.footer-links-section, 
.footer-help-support-section, 
.footer-contact-section {
  flex: 1;
  margin: 10px;
}

.footer-logo {
  width: 150px;
  margin-bottom: 20px;
}

.footer-logo-section p {
  margin: 5px 0;
}

.social-icons img {
  width: 24px;
  margin-right: 10px;
  cursor: pointer;
  margin-left: 10px;
}

.footer-links-section h3,
.footer-help-support-section h3,
.footer-contact-section h3 {
  margin-bottom: 15px;
}

.footer-links-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-links-section ul li {
  margin-bottom: 10px;
}

.footer-links-section ul li a {
  color: white;
  text-decoration: none;
}

.app-store-links img {
  width: 120px;
  margin-top: 15px;
  margin-right: 10px;
}

.certification-logos img {
  width: 80px;
  margin-top: 35px;
  margin-right: 10px;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  border-top: 1px solid #fff;
  padding-top: 10px;
  flex-wrap: wrap;
}

.footer-bottom p {
  margin: 5px 0;
}


.drawer-main-box{
width: 369px;
height:auto;
background-color: #274699;
padding: 30px 0px 30px 0px;
}

.drawer-sub-box{
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-evenly; */
  color: white;
  padding: 5px;
  margin-left: 40px;
}